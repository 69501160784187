<template>
  <div>
    <WsCreate
      ref="WsCreate"
      :modelName="model.tulpa_cross_item.modelName"
      :label="model.tulpa_cross_item.label"
      :fields="model.tulpa_cross_item.fields"
      :primary="pageSetting.primary"
      :secondary="_pageSettingSecondary"
      v-model="state"
      :emitSubmit="true"
      @submit="$_onSubmit()"
      :emitInput="true"
    >
    </WsCreate>
    <WsMain class="pb-60">
      <WsStateTulpaSectionContent
        v-if="state.tulpa_section"
        v-model="state.content"
        :tulpaSection="state.tulpa_section"
        defaultContentKey="content"
      />
    </WsMain>
  </div>
</template>

<script>
import tulpa_cross_item from "@/__vue2stone_cms/models/tulpa_cross_item";
import S_TulpaCrossItem from "@/__vue2stone_cms/service/api/v1/tulpa_cross_item";
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
import S_App_State from "@/__vue2stone_cms/service/app/state";
export default {
  metaInfo() {
    return {
      title: `${this.$t("new")} ${this.$t("跨網站項目")}`,
    };
  },

  computed: {
    _pageSettingSecondary() {
      const fields = ["tulpa_section"];
      if (S_App_Auth.hasScope(["boss"])) {
        if (this.$config.auth.admin_group) {
          if (this.$config.auth.admin_blur) {
            fields.push("cmser_groups");
          } else {
            fields.push("admin_groups");
          }
        }
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              title: "項目設定",
              titleInLocale: true,
              fields: fields,
            },
          ],
        },
      ];
    },
  },

  data: () => ({
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name", "position"],
            },
          ],
        },
      ],
    },
    model: {
      tulpa_cross_item,
    },
    state: {
      content: {},
    },
  }),

  methods: {
    async $_onSubmit() {
      const postData = S_App_State.getFormatedStates(
        this.model.tulpa_cross_item.fields,
        this.state,
        true
      );
      try {
        this.$refs.WsCreate.loadingStart();
        await S_TulpaCrossItem.create(postData);
        this.$router.push("/tulpa_cross_item");
      } catch (error) {
        console.error(error);
        alert(this.$t("error"));
      } finally {
        this.$refs.WsCreate.loadingStop();
      }
    },
  },

  watch: {
    // "state.tulpa_section": {
    //   handler() {
    //     this.state.content = {};
    //   },
    // },
  },
};
</script>

<style>
</style>